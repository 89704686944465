/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    @font-face {
        font-family: 'GT America';
        font-weight: 700;
        src: url(./assets/fonts/GT-America/GTAmericaBold/GTAmericaBold.woff);
    }
    @font-face {
        font-family: 'GT America';
        font-weight: 400;
        src: url(./assets/fonts/GT-America/GTAmericaRegular/GTAmericaRegular.woff);
    }
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom.css-1spb1s5 {
    background: unset!important;
}

.rsm-geography:hover {
    z-index: 9999!important;
}

.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: unset;
}

.pagination li {
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    padding: 4px 13px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
    width: 240px;
}

.activeLink {
    position: relative;
}

.activeLink::after {
    content: '';
    position: absolute;
    background-color: #2A538A;
    color: #2A538A;
    font-weight: bold;
    width: 100%;
    height: 6px;
    bottom: -8px;
    left: 0;
}

[aria-label="Post 2, Instagram post by Curator.io Powered by Curator.io "] {
    display: none;
    background-color: red;
}

.tab-panel .Mui-selected {
    background: #3A82B2;
    color: #FFFFFF!important;
}

.tab-panel .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    display: none;
}

.crt-load-more {
    display: none!important;
}

.crt-post-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
}

#simple-tabpanel-0 .crt-post-text {
    padding: 0 17px 28px;
    color: #707070;
    font-size: 1em;
    line-height: 1.2em;
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
    height: 60px;
    margin-bottom: 10px;
}

#simple-tabpanel-1 .crt-widget .crt-post-text {
    display: none;
}

[multiple]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=email]:focus,
[type=month]:focus,
[type=number]:focus,
[type=password]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=text]:focus,
[type=time]:focus,
[type=url]:focus,
[type=week]:focus,
select:focus,
textarea:focus {
    box-shadow: unset;
    border-color: #6b7280;
}

.crt-post-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* #curator-feed-new-feed-layout .crt-feed div {
    width: 442px!important;
} */

.item-nth .flex:nth-child(even) {
    padding-top: 102px;
    padding-bottom: 102px;
    flex-direction: row-reverse;
}

svg {
    display: inline-block;
    vertical-align: middle;
}

.testT {
    width: 73px;
    height: 53px;
}

.tooltip {
    display: flex;
    column-gap: 14px;
    border-bottom: 2px solid #000;
    padding-bottom: 14px;
}

.tooltip-main {
    padding: 11px 20px;
    background: #fff;
    color: #202021;
}

.see-more {
    padding-top: 20px;
}

.tooltip-main i {
    color: #343434;
}

.tooltip span {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #202021;
}

.css-ja5taz-MuiTooltip-tooltip {
    background-color: unset!important;
    margin-bottom: 0!important;
    margin-top: 0!important;
}

.react-tabs {
    background-color: #E9EDF6;
}

.react-tabs__tab-panel--selected {
    padding-top: 10%;
}

.react-tabs__tab {
    border: unset!important;
    bottom: unset!important;
    padding: unset!important;
}

.react-tabs__tab--selected {
    background-color: unset!important;
}

.react-tabs__tab--selected div {
    background-color: #254A7C!important;
    color: white !important;
}

@media only screen and (max-width: 600px) {
    #curator-feed-new-feed-layout .crt-feed div {
        width: 100%!important;
    }
    .testT {
        width: 53px;
        height: 35px;
    }
    .tooltip span {
        font-size: 15px;
    }
    .tooltip-main {
        padding: 11px 10px;
    }
}

.react-tabs__tab:focus:after {
    position: unset!important;
}

@media only screen and (max-width: 768px) {
    #topDates .css-1869usk-MuiFormControl-root {
        min-width: 98px;
    }
    #topDates .css-ece9u5 {
        min-width: unset;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .test-map {
        padding-top: 60px;
    }
}

.MuiTooltip-popper[data-popper-placement*="top"].css-ja5taz-MuiTooltip-tooltip {
    margin-bottom: 0!important;
}


/* .test-map{
  position: relative;
}
.test{
  width: 200px;
  height: 200px;
  background-color: red;
  position: absolute;
  top: 0;
} */

.test-map {
    cursor: pointer;
}

textarea {
    resize: none;
}

.css-1k51tf5-MuiTooltip-tooltip {
    background-color: #fff !important;
}

.css-kudwh-MuiTooltip-arrow,
.css-1urvb1y {
    color: #fff !important;
    width: 4em !important;
    height: 2.91em !important;
}

.css-1xjiak[data-popper-placement*="top"] .MuiTooltip-arrow {
    margin-top: -2.71em !important;
}

.css-1xjiak[data-popper-placement*="bottom"] .MuiTooltip-arrow {
    margin-top: -2.71em !important;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementBottom.css-1k51tf5-MuiTooltip-tooltip,
.css-8y3x9n {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
    margin: 0 !important;
    padding: 0 !important;
}

.css-kudwh-MuiTooltip-arrow::before {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
    margin-top: -2.71em !important;
}

.css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-arrow {
    margin-bottom: -2.71em !important;
}

.css-1xjiak[data-popper-placement*="top"] .MuiTooltip-arrow {
    bottom: 0px;
    margin-bottom: -2.71em;
}

.MuiTooltip-popper[data-popper-placement*="top"] .css-1k51tf5-MuiTooltip-tooltip {
    margin-bottom: -32px!important;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .css-1k51tf5-MuiTooltip-tooltip {
    margin-top: 0px!important;
}


/* Dosya girişi bileşeni için stil */

.file-input-container {
    display: inline-block;
    position: relative;
}

.file-input-label {
    display: inline-block;
    background-color: #007bff;
    /* Değiştirmek istediğiniz arka plan rengini buraya ekleyin */
    color: #ffffff;
    /* Yazı rengini buraya ekleyin */
    /* padding: 8px 20px; */
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}


/* Dosya girişi gizli tutmak için CSS */

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 1rem;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
    background-color: white;
    border: 5px solid transparent;
    border-radius: 15px;
    background-clip: content-box;
    height: 100px;
}

@media only screen and (max-width: 850px) {
     ::-webkit-scrollbar {
        display: none;
    }
    /* ::-webkit-scrollbar-track {
        background-color: black;
    }
     ::-webkit-scrollbar-thumb {
        background-color: white;
        border: 5px solid transparent;
        border-radius: 15px;
        background-clip: content-box;
        height: 100px;
    } */
}

.css-1hjrgzd-MuiTooltip-tooltip {
    background-color: white !important;
}

.css-12lokqy {
    background: white !important;
}

.css-1xjiak[data-popper-placement*="top"] .MuiTooltip-arrow {
    bottom: 0px;
    margin-bottom: -2.71em !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #000;
}

div:where(.swal2-container) {
    z-index: 9999999 !important;
}