.css-1869usk-MuiFormControl-root {
    margin: 0 !important;
    background-color: white !important;
}

.css-qfxojq-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
    font-size: 14px !important;
    background-color: white !important;
}

.css-b1884d {
    margin: 0 !important;
}

.css-r68l2g {
    background-color: white !important;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    max-height: calc(50% - 32px) !important;
}

.css-lh3xye-MuiFormControl-root {
    margin: 0 !important;
}