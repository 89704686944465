.calendar__dialog .css-hz1bth-MuiDialog-container,
.calendar__dialog .css-ekeie0 {
    height: auto !important;
    justify-content: flex-start !important;
}

.calendar__dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.calendar__dialog .css-uhb5lp {
    margin: 0 !important;
    border: 0 !important;
    border-radius: unset;
}

.calendar__dialog .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop,
.calendar__dialog .css-919eu4 {
    background-color: rgba(0, 0, 0, 0) !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
    background-color: white;
    border: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
}

.fc-h-event .fc-event-title {
    font-weight: 700;
}

.fc .fc-daygrid-event-harness {
    margin-bottom: 5px;
}

.fc-theme-standard td,
.fc-theme-standard th {
    padding: 0px 10px;
}

.fc .fc-daygrid-day-number {
    font-weight: 700;
    color: #696969;
}

.fc .fc-button-primary {
    background-color: white;
    border: none;
    color: black;
}

.fc .fc-button-primary:hover {
    background-color: transparent;
    color: #4A4A4A;
    font: 700;
}

.fc .fc-button .fc-icon {
    color: #696969;
}

.fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    padding: 0px 10px;
    cursor: pointer !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    border-width: 3px !important;
    border-radius: unset !important;
}

@media screen and (max-width: 768px) {
    .fc-toolbar-title {
        font-size: 15px !important;
    }
}