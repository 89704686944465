/* Make the dropdown animate. */

.dropdown-content {
    min-width: max-content;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.4s;
    background-color: black;
    padding: 0px 20px;
}


/* The max-height should be set to something that will always be a little bit bigger than the height of the content. */

.dropdown:hover .dropdown-content,
.dropdown:focus .dropdown-content,
.dropdown:focus-within .dropdown-content {
    max-height: 250px;
}


/* This makes the top-level navigation horizontal on desktop, and vertical on smaller screens. */

@media screen and (min-width: 1024px) {
    nav>ul>li {
        float: left;
    }
    .dropdown {
        position: relative;
    }
    .dropdown-content {
        position: absolute;
        width: max-content;
    }
    .content {
        clear: both;
    }
}