.swal2-styled.swal2-confirm {
    background-color: #2A538A;
    transition: all 0.3s ease;
    font-weight: 700;
    border: 1px solid #2A538A;
}

.swal2-styled.swal2-confirm:hover {
    background-color: white;
    color: #2A538A;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: none;
}