/* This is for swiper slider in home page -- start*/

.testimonials-swiper .swiper-slide {
    display: flex!important;
    width: 80%!important;
}

.testimonials-swiper .swiper-pagination {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    left: 0;
    text-align: unset;
}

.testimonials-swiper .swiper-wrapper {
    margin-bottom: 66px;
}

.testimonials-swiper .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 52px))!important;
    ;
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 1px))!important;
    ;
    display: inline-block;
    border-radius: unset!important;
    border: 1px solid #202021!important;
    ;
}

.testimonials-swiper .swiper-pagination-bullet-active {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 52px))!important;
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 6px))!important;
    background: #202021;
}


/* This is for swiper slider in home page -- end*/