@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);

/* ------------ */

.text-primary {
    color: #5a8dee !important;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
}

.rc-accordion-toggle {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    transition: 0.3s;
    column-gap: 14px;
}

.rc-accordion-toggle.active .rc-accordion-icon {
    transform: rotate(180deg);
}

.rc-accordion-card {
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    width: 700px;
}

.rc-accordion-card:last-child {
    margin-bottom: 0;
}

.rc-accordion-title {
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: #202021;
    transition: 0.3s;
}

.rc-accordion-icon {
    position: relative;
    top: 6px;
    color: #202021;
    transition: 0.35s;
    font-size: 12px;
}

.bg {
    background: #F0F0F0;
}

.rc-accordion-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}

.rc-accordion-body p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #202021;
}

.rc-collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.rc-collapse.show {
    height: auto;
}

@media screen and (max-width: 768px) {
    .rc-accordion-card {
        width: 100% !important;
    }
    .rc-collapse {
        height: auto;
    }
}