.vertical-timeline.vertical-timeline--two-columns::before {
    background-color: #202021 !important;
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    display: flex !important;
    flex-direction: column-reverse !important;
}

.vertical-timeline::before {
    width: 1px !important;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    display: none !important;
}


/* 
.vertical-timeline-element--work {
    height: 60vh !important;
} */

.vertical-timeline-element-content {
    box-shadow: none;
    -webkit-box-shadow: none
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    padding: 0;
}