/* .swiper-button-next {
    color: black;
    font-size: 10px !important;
}

.swiper-button-prev {
    color: black;
} */

.swiper-button-prev,
.swiper-button-next {
    font-size: 5px;
    /* Change the font size of the arrows */
    color: black;
    /* Change the color of the arrows */
    background-color: transparent;
    /* Remove the background color */
    border: none;
    /* Remove the border */
    outline: none;
    /* Remove the outline */
    pointer-events: all;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    pointer-events: all;
}

.swiper-pagination {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    text-align: unset;
    display: flex;
    justify-content: center;
}

.swiper-wrapper {
    margin-bottom: 66px;
}

.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 52px))!important;
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 1px))!important;
    display: inline-block;
    border-radius: unset!important;
    border: 1px solid #202021!important;
}

.swiper-pagination-bullet-active {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 52px))!important;
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 6px))!important;
    background: #202021;
}

.swiper-pagination-bullet {
    padding: 1px 0;
}